
import './App.css';
import axios from "axios";
import { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";


const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
  },
  responseType: 'json',
});




api.interceptors.request.use(async (config) => {
  const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();
  console.log(idToken);
  if (idToken) config.headers['Authorization'] = 'Bearer ' + idToken;
  return config;
});

const fetchConfig = async () => {
  try {
    const response = await fetch('/config');
    if (!response.ok) {
      throw new Error('Failed to fetch configuration');
    }
    const config = await response.json();

    // Configure Amplify with fetched Cognito configuration
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.aws_user_pools_id,
          userPoolClientId: config.aws_user_pools_web_client_id
        }
      }
    });
  } catch (error) {
    console.error('Error fetching configuration:', error);
  }
};

await fetchConfig();

function App() {
  const [brands, setBrands] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await api.get("/api/brands");
        setBrands(response.data);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };

    fetchConfig();
  }, []);
  console.log(brands);
  return (
    <div className="App">
      {brands &&
        <ul>{brands.map(b => { return <li>{b.name}</li> })}</ul>}
      <div onClick={() => signOut()}> LOGOUT</div>
    </div>
  );
}

export default withAuthenticator(App);
